/* All animation styles */

.Eureka-rotates_cartoon {
  animation: rotates_cartoon 4s linear infinite;
}
@keyframes rotates_cartoon {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.Eureka-opacity_cartoon {
  animation: opacity_cartoon 4s linear infinite;
}
@keyframes opacity_cartoon {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
}

.Eureka-genloading_cartoon {
  animation: genloading_cartoon 1s linear infinite;
}
@keyframes genloading_cartoon {
  0% { opacity: 1; }
  10% { opacity: 0; }
  30% { opacity: 0; }
  40% { opacity: 1; }
  100% { opacity: 1; }
}