/* @import url('https://fonts.googleapis.com/css?family=Outfit'); */
/* fonts import */
@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-Black.ttf') format('truetype'); font-weight: 900; font-style: normal; }
@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-BlackItalic.ttf') format('truetype'); font-weight: 900; font-style: italic; }

@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-ExtraBold.ttf') format('truetype'); font-weight: 800; font-style: normal; }
@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-ExtraBoldItalic.ttf') format('truetype'); font-weight: 800; font-style: italic; }

@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-Bold.ttf') format('truetype'); font-weight: 700; font-style: normal; }
@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-BoldItalic.ttf') format('truetype'); font-weight: 700; font-style: italic; }

@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-SemiBold.ttf') format('truetype'); font-weight: 600; font-style: normal; }
@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-SemiBoldItalic.ttf') format('truetype'); font-weight: 600; font-style: italic; }

@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-Medium.ttf') format('truetype'); font-weight: 500; font-style: normal; }
@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-MediumItalic.ttf') format('truetype'); font-weight: 500; font-style: italic; }

@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-Regular.ttf') format('truetype'); font-weight: 400; font-style: normal; }

@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-Light.ttf') format('truetype'); font-weight: 300; font-style: normal; }
@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-LightItalic.ttf') format('truetype'); font-weight: 300; font-style: italic; }

@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-ExtraLight.ttf') format('truetype'); font-weight: 200; font-style: normal; }
@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-ExtraLightItalic.ttf') format('truetype'); font-weight: 200; font-style: italic; }

@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-Thin.ttf') format('truetype'); font-weight: 100; font-style: normal; }
@font-face { font-family: Inter; src: url('../fonts/Inter_18pt-ThinItalic.ttf') format('truetype'); font-weight: 100; font-style: italic; }

/* video font */
@font-face { font-family: "Roboto black"; src: url('../fonts/Roboto-Black.ttf') format('truetype'); font-weight: 900; font-style: normal; }
@font-face { font-family: "Roboto black"; src: url('../fonts/Roboto-BlackItalic.ttf') format('truetype'); font-weight: 900; font-style: italic; }
@font-face { font-family: "Roboto light"; src: url('../fonts/Roboto-Light.ttf') format('truetype'); font-weight: 300; font-style: normal; }
@font-face { font-family: "Roboto bold"; src: url('../fonts/Roboto-Bold.ttf') format('truetype'); font-weight: 700; font-style: normal; }


@font-face { font-family: "Poppins black"; src: url('../fonts/Poppins-Black.ttf') format('truetype'); font-weight: 900; font-style: normal; }

@font-face { font-family: "Blenda script"; src: url('../fonts/Blenda-Script.otf') format('truetype'); }

@font-face { font-family: "Nunito black"; src: url('../fonts/Nunito-Black.ttf') format('truetype'); }
@font-face { font-family: "Komika"; src: url('../fonts/komika.ttf') format('truetype'); }
@font-face { font-family: "Pervitina"; src: url('../fonts/Pervitina-Black.ttf') format('truetype'); }

@font-face { font-family: "Montserrat black"; src: url('../fonts/Montserrat-Black.ttf') format('truetype'); }
@font-face { font-family: "Montserrat black italic"; src: url('../fonts/Montserrat-BlackItalic.ttf') format('truetype'); }
@font-face { font-family: "Montserrat extra bold"; src: url('../fonts/Montserrat-ExtraBold.ttf') format('truetype'); }
@font-face { font-family: "Good vibes"; src: url('../fonts/good-vibes.ttf') format('truetype'); }

/* satoshi */
@font-face { font-family: Satoshi; src: url('../satoshi-font/Satoshi-Black.ttf') format('truetype'); font-weight: 900; font-style: normal; }
@font-face { font-family: Satoshi; src: url('../satoshi-font/Satoshi-BlackItalic.ttf') format('truetype'); font-weight: 900; font-style: italic; }

@font-face { font-family: Satoshi; src: url('../satoshi-font/Satoshi-Bold.ttf') format('truetype'); font-weight: 700; font-style: normal; }
@font-face { font-family: Satoshi; src: url('../satoshi-font/Satoshi-BoldItalic.ttf') format('truetype'); font-weight: 700; font-style: italic; }

@font-face { font-family: Satoshi; src: url('../satoshi-font/Satoshi-Light.ttf') format('truetype'); font-weight: 300; font-style: normal; }
@font-face { font-family: Satoshi; src: url('../satoshi-font/Satoshi-LightItalic.ttf') format('truetype'); font-weight: 300; font-style: italic; }

@font-face { font-family: Satoshi; src: url('../satoshi-font/Satoshi-Medium.ttf') format('truetype'); font-weight: 500; font-style: normal; }
@font-face { font-family: Satoshi; src: url('../satoshi-font/Satoshi-MediumItalic.ttf') format('truetype'); font-weight: 500; font-style: italic; }

@font-face { font-family: Satoshi; src: url('../satoshi-font/Satoshi-Regular.ttf') format('truetype'); font-weight: 400; font-style: normal; }

/* clash-display */
@font-face { font-family: ClashDisplay; src: url('../clashDisplay-font/ClashDisplay-Bold.ttf') format('truetype'); font-weight: 700; font-style: normal; }
@font-face { font-family: ClashDisplay; src: url('../clashDisplay-font/ClashDisplay-Extralight.ttf') format('truetype'); font-weight: 200; font-style: normal; }
@font-face { font-family: ClashDisplay; src: url('../clashDisplay-font/ClashDisplay-Light.ttf') format('truetype'); font-weight: 300; font-style: normal; }
@font-face { font-family: ClashDisplay; src: url('../clashDisplay-font/ClashDisplay-Medium.ttf') format('truetype'); font-weight: 500; font-style: normal; }
@font-face { font-family: ClashDisplay; src: url('../clashDisplay-font/ClashDisplay-Regular.ttf') format('truetype'); font-weight: 400; font-style: normal; }
@font-face { font-family: ClashDisplay; src: url('../clashDisplay-font/ClashDisplay-Semibold.ttf') format('truetype'); font-weight: 600; font-style: normal; }
@font-face { font-family: ClashDisplay; src: url('../clashDisplay-font/ClashDisplay-Variable.ttf') format('truetype'); font-weight: 100; font-style: normal; }

@font-face { font-family: AmazonEmber; src: url("../fonts/AmazonEmber_Rg.ttf") format('truetype'); font-weight: 400; font-style: normal; }
@font-face { font-family: Archivo; src: url("../fonts/Archivo-Bold.ttf") format('truetype'); font-weight: 700; font-style: normal; }
/* video font */

@font-face { font-family: Georgia; src: url("../georgia-font/georgia.ttf") format('truetype'); font-weight: 400; font-style: normal; }
@font-face { font-family: "Georgia Bold"; src: url("../georgia-font/georgiab.ttf") format('truetype'); font-weight: 700; font-style: normal; }


html,
body,
#root {
  height: 100%;
  font-size: 0.14rem;
  letter-spacing: -0.1px;
  font-family: Inter;
}

body {
  overflow-y: auto;
}

p {
  letter-spacing: 0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

input, textarea { font-family: Inter; outline: none; }

/* font style is xxx... */
.ellipsis { text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
.textOverflow { text-overflow: ellipsis; }

.text-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.imgObjectFit { object-fit: cover; }

*:focus { outline: none; }

.cursorHand { cursor: pointer; }

.circle { border-radius: 50%; }

.scroll-sticky-top { position: sticky; top: 0; }

/* google chrome supports minimum font size 12px */
/*
.chrome12pxfix10px {
  font-size: 12px;
  -webkit-transform: scale(0.83);
} */

/* this is to customize the border color joyride tooltip */
.__floater__open {
  filter: drop-shadow(0px 0px 2px #235DFF) !important;
}
/* scrollbar all style */
/* * {
  scrollbar-color: #c7bdf6 #ebe7fc;
  scrollbar-width: thin;
} */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: #484c55;
  border-radius: 4px;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: #ebe7fc;
}

/* file uploader drag style */
.drag-drop-upload-focus {
  box-shadow: 0rem 0rem 0.625rem #235DFF;
}
.drag-drop-upload-normal {
  box-shadow: none;
}

.transform-opacity_zero_input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  line-height: 1;
}

/* Transparent input box */
.input-transparent {
  background-color: transparent !important;
  border: none;
  outline: none;
  resize: none;
  padding: 0;
  font-family: Inter;
}
.input-transparent::placeholder {
  color: #828282;
}
.input-transparent:focus,
.input-transparent:hover,
.input-transparent:active,
.input-transparent:focus-visible{
  outline: none;
  resize: none;
}


.input-primary {
  background-color: transparent;
  border: 1px solid #35383F;
  border-radius: 0.06rem;
  font-family: Inter;
}
.input-primary:focus{
  border-color: #235DFF;
}

